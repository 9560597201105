import demoDOH from '@/services/demoDOH';
import { getFilterQueryStringWithoutArray } from "@/helpers/helper.js";

const getDefaultState = () => {
    return {
        standAlonePQR: {},
    };
}
const state = getDefaultState()
const getters = {
    getStandAlonePQR: state => state.standAlonePQR,
}
const mutations = {
    ["SET_STAND_ALONE_PQR"](state, payload) {
        state.standAlonePQR = payload;
    },
    ["RESET_STAND_ALONE_PQR"](state) {
        state.standAlonePQR = {};
    },
}
const actions = {
    initDemoDOHPQRCheck({ getters, dispatch, commit }) {
        const {
            getCandidateType,
            getIssueAuthority,
            getLocationCountryInfo,
            getSpeciality,
            getSubSpeciality,
        } = getters;
        let appendFilterAction = [];
        commit("SET_FETCHING", true);
        commit("RESET_STAND_ALONE_PQR");
        if (!getCandidateType.length) {
            appendFilterAction = [...appendFilterAction, dispatch("fetchCandidateType")];
        }
        if (!getIssueAuthority.length) {
            appendFilterAction = [...appendFilterAction, dispatch("issueAuthority")];
        }
        if (!getLocationCountryInfo.length) {
            appendFilterAction = [...appendFilterAction, dispatch("locationCountryInfo")];
        }
        if (!getSpeciality.length) {
            appendFilterAction = [...appendFilterAction, dispatch("speciality")];
        }
        if (!getSubSpeciality.length) {
            appendFilterAction = [...appendFilterAction, dispatch("subSpeciality")];
        }
        return Promise.all(appendFilterAction).then(res => {
            commit("SET_FETCHING", false);
            return res;
        });
    },
    checkStandAlonePQR({ getters, commit, dispatch }, payload) {
        commit("RESET_STAND_ALONE_PQR");
        dispatch("showLoader");
        let queryString = getFilterQueryStringWithoutArray(payload);
        return demoDOH
            .checkStandAlonePQR({ queryString })
            .then(res => {
                const { data } = res;
                const doh_data = data.filter(({ pqr_name }) => pqr_name.includes("DoH"));
                commit("SET_STAND_ALONE_PQR", doh_data && doh_data.length ? doh_data[0] : {});
                return data;
            })
            .catch(err => {
                console.log("Error While Check Stand Alone PQR", err);
                dispatch('showToast', { class: 'bg-danger text-white', message: "Error while Check PQR" });
                return err;
            })
            .finally(res => {
                dispatch("hideLoader");
            })
    }
}
export default { actions, state, getters, mutations };