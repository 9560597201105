import course from "@/services/course";
import candidate from "@/services/candidate";
import * as _ from "lodash";
import { getFilterQueryStringWithoutArray } from "@/helpers/helper";
import router from "@/router/index";
import { getSkipLimitBypage, isEmptyObjectCheck, isObject } from "../helpers/helper";

const courseFilterSchema = {
    studentType: {
        component: "filterSelect",
        placeholder: "All",
        label: "Student Type",
        queryKey: "candidate_type_id",
        taggable: false,
        multiple: false,
        clearable: true,
    },
    courseType: {
        component: "filterSelect",
        placeholder: "All",
        label: "Course Type",
        queryKey: "course_test_type_id",
        taggable: false,
        multiple: false,
        clearable: true,
        dependentFilter: [
            "healthcare_sector",
            "org_dept_id"
        ]
    },
    healthcare_sector: {
        component: "filterSelect",
        label: "Healthcare Sector/Department",
        queryKey: "healthcare_sector",
        secondLevelDependentDisable: ["courseType"],
        taggable: false,
        multiple: false,
        clearable: true
    },
    org_dept_id: {
        component: "filterSelect",
        label: "Healthcare Sector/Department",
        secondLevelDependentDisable: ["courseType"],
        queryKey: "org_dept_id",
        taggable: false,
        multiple: false,
        clearable: true
    },
    courseLevel: {
        name: "courseLevel",
        component: "filterInput",
        label: "Course Level",
        queryKey: "course_level_id",
    },
    language: {
        component: "filterSelect",
        placeholder: "All",
        label: "Language Delivery",
        queryKey: "language_id__in",
        taggable: false,
        multiple: true,
        clearable: true,
    },
    delivery: {
        name: "delivery",
        component: "filterSelect",
        placeholder: "All",
        label: "Study Mode",
        queryKey: "delivery_mode",
        taggable: false,
        multiple: false,
        clearable: true,
    },
}
const getDefaultState = () => {
    return {
        coursePagination: {
            limit: 10,
            skip: 0,
            noMoreDataFromCourseList: false
        },
        deliveryMode: [],
        healthcareSector: [],
        trainingTypes: [],
        courseCount: {
            totalCount: 0,
            filteredCount: null
        },
        courseFilterSchema,
        courseList: [],
        courseInfo: null,
        isFetchingCourseList: false,
        courseTemplate: null,
        getCourseTitles: [],
        allDepartments: [],
        orgDepartments: [],
        iscedLevels: [],
        interviewQuestions: [],
        assessmentTestId: {},
        assessmentTestSummary: {},
    }
}

const state = getDefaultState()

function convertToOptions(data) {
    return data.map(v => ({
        code: v,
        label: _.startCase(v)
    }))
}

const getters = {
    getCoursePaginationLimit: (state) => state.coursePagination.limit,
    getCoursePaginationSkip: (state) => state.coursePagination.skip,
    courseDeliveryMode: (state) => convertToOptions(state.deliveryMode),
    courseHealthcareSector: (state) => convertToOptions(state.healthcareSector),
    courseFilterSchema: (state) => state.courseFilterSchema,
    trainingTypes: (state) => state.trainingTypes.map(v => ({
        code: v.training_type_id,
        label: v.training_type
    })),
    courseTotalCount: state => state.courseCount.totalCount,
    courseFilteredCount: state => state.courseCount.filteredCount,
    courseList: state => state.courseList,
    courseInfo: state => state.courseInfo,
    noMoreDataFromCourseList: state => state.coursePagination.noMoreDataFromCourseList,
    isFetchingCourseList: state => state.isFetchingCourseList,
    courseTemplate: state => state.courseTemplate,
    getCourseTitleList: state => state.getCourseTitles,
    getAllDepartments: state => state.allDepartments.map(v => ({
        code: v?.department_id,
        label: v?.department_name
    })) || [],
    getOrgDepartments: state => state.orgDepartments.map(v => ({
        code: v.organisation_department_id,
        label: v.department.department_name,
        department_id: v.department.department_id
    })) || [],
    getISCEDLevels: state => state.iscedLevels.map(v=> ({
        code: v.isced_id,
        label: `${v.level}-${v.description}`,
        level: v.level
    })).sort((a,b)=>b.level - a.level) || [],
    getInterviewQuestions: state => state.interviewQuestions || [],
    getAssessmentTestId: state => state.assessmentTestId || null,
    getAssessmentTestSummary: state => state.assessmentTestSummary || null,
}


const actions = {
    initCourseOptions: async ({ commit, getters, dispatch }) => {
        const { getCourseTestTypes,
            locationLanguage, courseDeliveryMode, courseHealthcareSector,
            getCurrency, trainingTypes, getOrgDepartments,
            getOrgIDFromAccessToken, getCandidateType, getLanguageExam,
            getCustomerId: customer_uid,
            getLocationCountryInfo, getISCEDLevels } = getters;
        let appendAction = [];
        commit("SET_FETCHING", true);
        if (!getCourseTestTypes?.length)
            appendAction = [...appendAction, dispatch("fetchCourseTestTypes")]

        if (!locationLanguage.length)
            appendAction = [...appendAction, dispatch("fetchLocationLanguage")];

        if (!courseDeliveryMode.length)
            appendAction = [...appendAction, dispatch("getDeliveryMode")];

        if (!courseHealthcareSector.length)
            appendAction = [...appendAction, dispatch("getHealthcareSector")];

        if (!getCurrency.length)
            appendAction = [...appendAction, dispatch("fetchCurrency")]

        if (!trainingTypes.length)
            appendAction = [...appendAction, dispatch("fetchTrainingTypes")]

        if (!getOrgDepartments.length)
            appendAction = [...appendAction, dispatch("fetchOrganisationDepartment", { organisation_id: getOrgIDFromAccessToken })]

        if (!getCandidateType.length)
            appendAction = [...appendAction, dispatch("fetchCandidateType")];

        if (!getLanguageExam.length)
            appendAction = [...appendAction, dispatch("fetchLanguageExam")];

        if (!getLocationCountryInfo.length)
            appendAction = [...appendAction, dispatch("locationCountryInfo")];

        if (!getISCEDLevels.length)
            appendAction = [...appendAction, dispatch("fetchISCEDLevels")];

        appendAction = [...appendAction, dispatch("fetchOrganizationId",{customer_uid})]

        const res = await Promise.all(appendAction)
        commit("SET_FETCHING", false);
        return res;
    },
    getDeliveryMode: ({ commit, getters, dispatch }) => {
        const enum_name = "deliverymode"
        const query = ""
        return candidate.getEnumLabels({ enum_name, query })
            .then(res => {
                const { data } = res;
                commit("SET_COURSE_DELIVERY_MODE", data)
                return res
            })
            .catch(err => {
                const message = "Error while get Delivery Mode";
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err)
                return err;
            })
    },
    getHealthcareSector: ({ commit, dispatch }) => {
        const enum_name = "healthcaresector"
        const query = ""
        return candidate.getEnumLabels({ enum_name, query })
            .then(res => {
                const { data } = res;
                commit("SET_COURSE_HEALTHCARE_SECTOR", data)
                return res
            })
            .catch(err => {
                const message = "Error while get Healthcare sector";

                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err)
                return err;

            })
    },
    fetchTrainingTypes: ({ commit, dispatch }) => {
        return course.getTrainingTypes().then(res => {
            const { data } = res;
            commit("SET_TRAINING_TYPES", data)
            return data
        })
            .catch(err => {
                const message = "Error while get Training Type";
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err)
                return err;
            })
    },
    createCourseModOpt: ({ getters, dispatch, commit }, payload) => {
        dispatch("showLoader");

        return course.createCourseModOpt(payload).then(res => {
            const { data } = res
            dispatch("hideLoader");
            dispatch("showToast", { class: 'bg-success text-white', message: 'Successfully Course Created!' })
            return res
        })
            .catch(err => {
                dispatch("hideLoader");
                const message = "Error while update course detail";
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err)
                return err;
            })
    },
    updateCourse: ({ getters, dispatch, commit }, payload) => {
        const { courseInfo } = getters;
        dispatch("showLoader");
        return course.updateCourse(payload).then(res => {
            const { data } = res
            dispatch("hideLoader");
            dispatch("showToast", { class: 'bg-success text-white', message: 'Successfully Course Detail Updated!' })
            const course = {
                ...courseInfo,
                training_course: data
            }
            commit("SET_COURSE_INFO", course)
            return res
        })
            .catch(err => {
                dispatch("hideLoader");
                const message = "Error while update course detail";
                console.log(message, err)
                dispatch("showToast", { class: 'bg-danger text-white', message })
                return err;
            })
    },
    createCourseModule: ({ getters, dispatch, commit }, payload) => {
        dispatch("showLoader");
        const { courseInfo } = getters
        return course.createCourseModule(payload).then(res => {
            const { data } = res
            dispatch("hideLoader");
            dispatch("showToast", { class: 'bg-success text-white', message: 'Successfully Course Detail Updated!' })
            if (courseInfo.training_course_modules)
                courseInfo.training_course_modules.push(data)
            commit("SET_COURSE_INFO", courseInfo)
            return res
        })
            .catch(err => {
                dispatch("hideLoader");
                const message = "Error while create course module";
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err)
                return err;
            })
    },
    updateCourseModule: ({ getters, dispatch, commit }, payload) => {
        dispatch("showLoader");
        const { courseInfo } = getters
        return course.updateCourseModule(payload).then(res => {
            const { data } = res
            dispatch("hideLoader");
            dispatch("showToast", { class: 'bg-success text-white', message: 'Successfully Course Module Updated!' })
            const training_course_modules = courseInfo?.training_course_modules
            const index = training_course_modules?.findIndex(v => v.training_course_mod_id === data.training_course_mod_id);
            if (index >= 0)
                training_course_modules[index] = data;
            const course = {
                ...courseInfo,
                training_course_modules
            }
            commit("SET_COURSE_INFO", course)
            return res
        })
            .catch(err => {
                dispatch("hideLoader");
                const message = "Error while update course module";
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err)
                return err;
            })
    },
    createCourseOption: ({ getters, dispatch, commit }, data = {}) => {
        dispatch("showLoader");
        const { courseInfo } = getters
        return course.createCourseOption(data).then(res => {
            const { data } = res
            dispatch("hideLoader");
            dispatch("showToast", { class: 'bg-success text-white', message: 'Successfully Course Option Created!' })
            if (courseInfo.training_course_options)
                courseInfo.training_course_options.push(data)
            commit("SET_COURSE_INFO", courseInfo)
            return res
        })
            .catch(err => {
                dispatch("hideLoader");
                const message = "Error while update course module";
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err)
                return err;
            })
    },
    updateCourseOption: ({ getters, dispatch, commit }, data = {}) => {
        const { courseInfo } = getters
        dispatch("showLoader");
        return course.updateCourseOption(data).then(res => {
            const { data } = res
            dispatch("hideLoader");
            dispatch("showToast", { class: 'bg-success text-white', message: 'Successfully Course Option Updated!' });
            const training_course_options = courseInfo?.training_course_options
            const index = training_course_options?.findIndex(v => v.training_course_opt_id === data.training_course_opt_id);
            if (index >= 0)
                training_course_options[index] = data;
            const course = {
                ...courseInfo,
                training_course_options
            }
            commit("SET_COURSE_INFO", course)
            return res
        })
            .catch(err => {
                dispatch("hideLoader");
                const message = "Error while update course option";
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err)
                return err;
            })
    },
    createCourseSemester: ({ getters, dispatch, commit }, payload) => {
        dispatch("showLoader");
        const { courseInfo } = getters
        return course.createCourseSemester(payload).then(res => {
            const { data } = res
            dispatch("hideLoader");
            dispatch("showToast", { class: 'bg-success text-white', message: 'Successfully Course Detail Updated!' })
            if (courseInfo.course_semesters)
                courseInfo.course_semesters.push(data)
            commit("SET_COURSE_INFO", courseInfo)
            return res
        })
            .catch(err => {
                dispatch("hideLoader");
                const message = "Error while create course semester";
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err)
                return err;
            })
    },
    updateCourseSemester: ({ getters, dispatch, commit }, payload) => {
        dispatch("showLoader");
        const { courseInfo } = getters
        return course.updateCourseSemester(payload).then(res => {
            const { data } = res
            dispatch("hideLoader");
            dispatch("showToast", { class: 'bg-success text-white', message: 'Successfully Course Module Updated!' })
            const course_semesters = courseInfo?.course_semesters
            const index = course_semesters?.findIndex(v => v.course_semester_id === data.course_semester_id);
            if (index >= 0)
                course_semesters[index] = data;
            const course = {
                ...courseInfo,
                course_semesters
            }
            commit("SET_COURSE_INFO", course)
            return res
        })
            .catch(err => {
                dispatch("hideLoader");
                const message = "Error while update course semester";
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err)
                return err;
            })
    },
    getCourseListCount: async ({ getters, dispatch, commit }, payload = {}) => {
        const { courseTotalCount, getOrgIDFromAccessToken: organisation_id } = getters;
        let queryPayload = { all_rows: true, fetch_row_count: true }
        if(organisation_id){
            queryPayload = {...queryPayload, organisation_id,}
        }
        const {query} = payload
        const queryString = `${getFilterQueryStringWithoutArray(queryPayload)}&${query}`
        return course.getCourses({ queryString }).then(res => {
            const { data } = res;
            commit("SET_COURSE_FILTERED_COUNT", data)
            if (!courseTotalCount)
                dispatch("getCourseListTotalCount")
            return res;
        })
            .catch(err => {
                const message = "Error while get course list count";
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err)
                return err;
            })
    },
    getCourseListTotalCount: ({ getters, dispatch, commit }) => {
        const { courseTotalCount, getOrgIDFromAccessToken: organisation_id } = getters;
        let queryPayload = { all_rows: true, fetch_row_count: true }
        if(organisation_id){
            queryPayload = {...queryPayload, organisation_id,}
        }
        const queryString = getFilterQueryStringWithoutArray(queryPayload)
        return course.getCourses({ queryString }).then(res => {
            const { data } = res;
            commit("SET_COURSE_TOTAL_COUNT", data)
            return res;
        })
            .catch(err => {
                const message = "Error while get course list total count";
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err)
                return err;
            })
    },
    getCourseList: async ({ getters, dispatch, commit }, payload = {}) => {
        commit("SET_FETCHING_COURSE_LIST", true)
        dispatch("showLoader");
        const { getCoursePaginationLimit, getCoursePaginationSkip, courseList, getOrgIDFromAccessToken: organisation_id } = getters
        let skip = getCoursePaginationSkip;
        const limit = getCoursePaginationLimit;

        if (!payload.pagination) {
            const skipLimit = await getSkipLimitBypage(payload?.pageNumber, getCoursePaginationLimit)
            skip = skipLimit.skip;
            commit("SET_COURSE_PAGINATION", {
                skip,
                noMoreDataFromCourseList: false,
            });
        }

        let { query } = payload;

        let queryPayload = {  skip, limit, all_rows: false, fetch_row_count: false }
        if(organisation_id){
            queryPayload = { ...queryPayload, organisation_id,}
        }
        const queryString = `${getFilterQueryStringWithoutArray(queryPayload)}&${query}`
        return course.getCoursesCombined({ queryString }).then(res => {
            const { data } = res
            dispatch("hideLoader");
            if (data.length < getCoursePaginationLimit) {
                commit("SET_COURSE_PAGINATION", {
                    noMoreDataFromCourseList: true
                });
            } else {
                commit("SET_COURSE_PAGINATION", {
                    skip: skip + getCoursePaginationLimit,
                    noMoreDataFromCourseList: false
                });
            }
            if (payload.pagination) {
                commit("SET_COURSE_LIST", courseList.concat(data));
            } else {
                commit("SET_COURSE_LIST", data);
            }
            if (!payload.pagination && !payload.isPageChanged) {
                dispatch("getCourseListCount", payload);
              }
            commit("SET_FETCHING_COURSE_LIST", false)
            return res
        })
            .catch(err => {
                dispatch("hideLoader");
                const message = "Error while get course list";
                console.log(message, err)
                dispatch("showToast", { class: 'bg-danger text-white', message })
                return err;
            })
    },
    getCourseById: ({ getters, commit, dispatch }, training_course_id) => {
        return course.getCourseById(training_course_id)
            .then(res => {
                const { data } = res;
                commit("SET_COURSE_INFO", data)
                return data;
            })
            .catch(err => {
                const message = "Error while get course detail";
                console.log(message, err)
                dispatch("showToast", { class: 'bg-danger text-white', message })
                return err;
            })
    },
    createCourseTemplate: ({ getters, commit, dispatch }, data = {}) => {
        const { training_course_id, payload } = data
        dispatch("showLoader")
        return course.createCourseTemplate({ training_course_id, payload })
            .then(res => {
                const { data } = res;
                dispatch("hideLoader")
                dispatch("showToast", { class: 'bg-success text-white', message: 'Successfully Course Template Created!' })
                return res;
            })
            .catch(err => {
                dispatch("hideLoader");
                const message = "Error while create course Template";
                console.log(message, err);
                dispatch("showToast", { class: 'bg-danger text-white', message })
                return err
            })
    },
    fetchCourseTemplate: ({ getters, commit, dispatch }, training_course_id) => {
        commit("RESET_COURSE_TEMPLATE")
        dispatch("showLoader");
        return course.getCourseTemplate(training_course_id)
            .then(res => {
                const { data } = res;
                commit("SET_COURSE_TEMPLATE", data)
                dispatch("hideLoader")
                return res;
            })
            .catch(err => {
                dispatch("hideLoader");
                const message = "Error while fetch course Template";
                console.log(message, err);
                dispatch("showToast", { class: 'bg-danger text-white', message })
                return err
            })
    },
    createInterestedCourseCandidate: ({ dispatch }, payload) => {
        return course.createInterestedCourseCandidate(payload)
            .then(res => {
                const { status, data } = res;
                if (status === 200) {
                    dispatch("showToast", {
                        class: "bg-success text-white",
                        message: "Submitted Successfully!",
                    });
                }
                return data
            })
            .catch(error => {
                const message = error.response?.data?.detail;
                dispatch("showToast", { class: "bg-danger text-white", message });
                console.error(error);
                return error
            })
    },
    fetchCourseTitleList: ({ commit, dispatch }, payload = {}) => {
        let queryPayload = { all_rows: true, fetch_row_count: false, ...payload }
        const queryString = getFilterQueryStringWithoutArray(queryPayload)
        return course.getCourses({ queryString }).then(res => {
            const { data } = res;
            commit("SET_COURSE_TITLE_LIST", data)
            return res;
        })
            .catch(err => {
                const message = "Error while get course Title list";
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err)
                return err;
            })
    },
    fetchAllDepartments: ({ commit, dispatch }) => {
        return course.getDepartments().then(res => {
            const { data } = res;
            commit('SET_ALL_DEPARTMENTS', data)
            return res;
        })
            .catch(err => {
                const message = "Error while get All Departments";
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err)
                return err;
            })
    },
    fetchOrganisationDepartment: ({ commit, dispatch }, payload = {}) => {
        const queryString = getFilterQueryStringWithoutArray(payload)
        return course.getOrgDepartments(queryString)
            .then(res => {
                const { data } = res;
                commit("SET_ORGANISATION_DEPARTMENT", data)
                return res;
            })
            .catch(err => {
                const message = "Error while get Organisation Departments";
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err)
                return err;
            })
    },
    fetchISCEDLevels:({commit, dispatch}) => {
        return course.getISCEDLevels()
        .then(res=> {
            const {data}=res;
            commit("SET_ISCED_LEVELS", data);
            return res;
        })
        .catch(err => {
            const message = "Error while get ISCED Levels";
            dispatch("showToast", { class: 'bg-danger text-white', message })
            console.log(message, err)
            return err;
        })
    },
    fetchInterviewQuestions:({commit, dispatch}, payload) => {
        dispatch("showLoader");
        const { skip = null, limit = null, fetch_rows_count, all_rows } = payload;
        let queryPayload = {}
        if (skip!== null && skip !== undefined) {
            queryPayload.skip = skip;
        }
        if (limit) {
            queryPayload.limit = limit;
        }
        if (fetch_rows_count) {
            queryPayload.fetch_rows_count = fetch_rows_count;
        }
        if (all_rows) {
            queryPayload.all_rows = all_rows;
        }
        const query = getFilterQueryStringWithoutArray(queryPayload)
        return course.getInterviewQuestions(query)
        .then(res=> {
            const { data } = res;
            commit("SET_INTERVIEW_QUESTIONS", data)
            dispatch("hideLoader");
            return res;
        })
        .catch(err => {
            dispatch("hideLoader");
            const message = "Error while getting Interview Questions";
            console.log(message, err)
            return err;
        })
    },
    createTestAssessmentId: ({ commit, getters }) => {
        const { getCandidateId } =  getters;
        const payload = {
            candidate_uid: getCandidateId
        }
        return course.createAssessmentTest(payload)
        .then(res=> {
            const { data } = res;
            commit("SET_ASSESSMENT_TEST_ID", data)
            return res;
        })
        .catch(err => {
            const message = "Error while creating assessment test";
            console.log(message, err)
            return err;
        })
    },
    createAssessmentTestSummary: ({ commit }, payload) => {
        return course.createAssessmentTestSummary(payload)
        .then(res=> {
            const { data } = res;
            commit("SET_ASSESSMENT_TEST_SUMMARY", data)
            return res;
        })
        .catch(err => {
            const message = "Error while creating assessment test summary";
            console.log(message, err)
            return err;
        })
    }
    
}
const mutations = {
    ["SET_COURSE_PAGINATION"](state, payload) {
        state.coursePagination = { ...state.coursePagination, ...payload }
    },
    ["SET_COURSE_DELIVERY_MODE"](state, payload) {
        state.deliveryMode = payload
    },
    ["SET_COURSE_HEALTHCARE_SECTOR"](state, payload) {
        state.healthcareSector = payload
    },
    ["SET_TRAINING_TYPES"](state, data) {
        state.trainingTypes = data;
    },
    ["SET_COURSE_TOTAL_COUNT"](state, data) {
        state.courseCount.totalCount = data;
    },
    ["SET_COURSE_FILTERED_COUNT"](state, data) {
        state.courseCount.filteredCount = data;
    },
    ["SET_COURSE_LIST"](state, data) {
        state.courseList = data
    },
    ["SET_COURSE_INFO"](state, data) {
        state.courseInfo = data;
    },
    ["SET_FETCHING_COURSE_LIST"](state, data) {
        state.isFetchingCourseList = data
    },
    ["SET_COURSE_TEMPLATE"](state, data) {
        state.courseTemplate = data
    },
    ["RESET_COURSE_TEMPLATE"](state) {
        state.courseTemplate = null
    },
    ["SET_COURSE_TITLE_LIST"](state, data) {
        state.getCourseTitles = data
    },
    ["SET_ALL_DEPARTMENTS"](state, data) {
        state.allDepartments = data;
    },
    ["SET_ORGANISATION_DEPARTMENT"](state, data) {
        state.orgDepartments = data;
    },
    ["SET_ISCED_LEVELS"](state, data) {
        state.iscedLevels = data;
    },
    ["SET_INTERVIEW_QUESTIONS"](state, data) {
        state.interviewQuestions =  data
    },
    ["SET_ASSESSMENT_TEST_ID"](state, data) {
        state.assessmentTestId =  data
    },
    ["SET_ASSESSMENT_TEST_SUMMARY"](state, data) {
        state.assessmentTestSummary =  data
    },

}


export default { state, getters, mutations, actions };