import dashboard from "@/services/dashboard";
import moment from "moment";
import { getFilterQueryStringWithoutArray } from "@/helpers/helper.js"

const getDefaultState = () => {
    return {
        getCandidateByType: {},
        getCandidateByTypeId: {},
        getAllInterviewFeedback: [],
    };
}

const state = getDefaultState()


const getters = {
    getCandidateByType: state => state.getCandidateByType,
    getCandidateByTypeId: state => state.getCandidateByTypeId,
    getAllInterviewFeedback: state => state.getAllInterviewFeedback,
};

const mutations = {
    ["SET_CANDIDATE_BY_TYPE"](state, payload) {
        state.getCandidateByType = payload;
    },
    ["SET_CANDIDATE_BY_TYPE_ID"](state, payload) {
        state.getCandidateByTypeId = payload;
    },
    ["SET_CONVERSATION"](state, data) {
        state.getConversation = data;
    },
    ['SET_ALL_INTERVIEW_FEEDBACK'](state, data) {
        state.getAllInterviewFeedback = data;
    },
    ['RESET_ALL_INTERVIEW_FEEDBACK'](state) {
        state.getAllInterviewFeedback = [];
    },
};

const actions = {
    FetchCandidateByType: ({ state, commit, rootState, dispatch, getters }) => {
        return dashboard
            .getCandidateByType()
            .then(async res => {
                commit("SET_CANDIDATE_BY_TYPE", res.data);
                return res;
            })
            .catch(err => {
                console.log("error fetching candidate by type", err);
                return err;
            });
    },

    fetchCandidateByTypeId: ({ state, commit, rootState, dispatch, getters }) => {
        const { getCustomerId, getIsChildUser, getOrgID } = getters;
        let query = getIsChildUser ? `organisation_id=${getOrgID}` : ""
        return dashboard
            .getCandidateByTypeStats(getCustomerId, query)
            .then(async res => {
                commit("SET_CANDIDATE_BY_TYPE_ID", res.data);
                return res;
            })
            .catch(err => {
                console.log("error fetching candidate by type", err);
                return err;
            });
    },
    htmlToPDF: ({ state, commit, dispatch, getters }, payload) => {
        dispatch("showFixedToast",
            { class: 'bg-secondary text-white', message: 'Preparing to Download...' })
        const { html_string, file_name } = payload
        let formData = new FormData();
        formData.append("html_string", html_string)
        return dashboard
            .htmlToPDF(formData)
            .then(res => {
                if (res.status === 200) {
                    let blob = new Blob([res.data], { type: 'application/pdf' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    const dateTime = moment.utc().local().format('DDMMMYYHHmmss');
                    link.download = `${file_name}_${dateTime}.pdf`
                    link.click()
                }
                return res;
            })
            .catch(err => {
                const message = "Error While Download File"
                console.log(message, err)
                dispatch("showToast",
                    { class: 'bg-danger text-white', message })
                return err;
            }).finally(() => {
                dispatch("hideFixedToast")
            })
    },
    fetchAllInterviewFeedback: ({ commit, dispatch, getters }, payload = {}) => {
        commit("RESET_ALL_INTERVIEW_FEEDBACK");
        // dispatch("showLoader");
        const { job_id, candidate_uid } = payload
        let queryPayload = {};
        if (job_id)
            queryPayload = { ...queryPayload, job_id }
        if (candidate_uid)
            queryPayload = { ...queryPayload, candidate_uid }
        const queryString = getFilterQueryStringWithoutArray(queryPayload)
        return dashboard
            .getAllInterviewFeedback(queryString)
            .then(res => {
                const { data } = res;
                commit("SET_ALL_INTERVIEW_FEEDBACK", data);
                return res;
            })
            .catch(err => {
                const message = "Error While Fetching Interview Evalutions";
                console.log(message, err);
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message,
                })
                return err;
            })
            .finally(() => {
                // dispatch("hideLoader");
            })
    },
    
    createReportIssue: ({ dispatch }, payload) => {
        return dashboard
            .createReportIssue(payload)
            .then((res) => {
                dispatch("showToast", {
                    class: "bg-success text-white",
                    message: "Reported Successfully",
                });
                return res;
            })
            .catch((err) => {
                const message = err.response.data.detail
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message,
                });
                console.log(message, err);
                return err;
            });
    },
};

export default { state, getters, mutations, actions };