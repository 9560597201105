import customerDocumentLib from "@/services/customerDocumentLib.js";
import organisationDocuments from "@/services/organisationDocuments";
import { getFilterQueryStringWithoutArray } from "@/helpers/helper.js";
const getDefaultState = () => {
    return {
        customerDocumentTypes: [],
        customerDocTypeCategory: [],
        documentExtensions: [],
        customerDocumentLibrary: [],
        jobDocumentActions: [],
        offerDocumentActions: [],
        onboardDocumentActions: [],
        preOfferDocumentActions: [],
        licensingDocumentActions: [],
        visaDocumentActions: [],
        joiningDocumentActions: [],
    }
}

const categoryOrder = [
    "job",
    "pre_offer",
    "offer",
    "licensing",
    "visa",
    "joining",
    "additional"
]
const state = getDefaultState();

const getters = {
    customerDocumentTypes: (state) => state.customerDocumentTypes && state.customerDocumentTypes.length ?
        state.customerDocumentTypes
        : [],
    customerDocTypeCategory: (state) => state.customerDocTypeCategory && state.customerDocTypeCategory.sort((a, b) => categoryOrder.indexOf(a) - categoryOrder.indexOf(b)
    ) || [],
    documentExtensions: (state) => state.documentExtensions && state.documentExtensions.map(v => ({ code: v?.document_ext_id, label: v?.document_ext })) || [],
    customerDocumentLibrary: (state) => state.customerDocumentLibrary || [],
    customerDocumentLibBasedOnType: (state) => ({ candidate_type_id, category }) => {
        return state.customerDocumentLibrary
            .filter(v => v.candidate_type_ids.includes(candidate_type_id) && category.includes(v?.category))
            .map(v => ({ code: v?.document_library_id, label: `${v?.customer_document_type?.type_name} | ${v?.library_name}`, data: v, tile: `${v?.customer_document_type?.type_name} | ${v?.library_name}` }))
            || []
    },
    jobDocumentActions: (state) => state.jobDocumentActions && state.jobDocumentActions || [],
    offerDocumentActions: (state) => state.offerDocumentActions && state.offerDocumentActions || [],
    onboardDocumentActions: (state) => state.onboardDocumentActions && state.onboardDocumentActions || [],
    preOfferDocumentActions: (state) => state.preOfferDocumentActions && state.preOfferDocumentActions || [],
    licensingDocumentActions: (state) => state.licensingDocumentActions && state.licensingDocumentActions || [],
    visaDocumentActions: (state) => state.visaDocumentActions && state.visaDocumentActions || [],
    joiningDocumentActions: (state) => state.joiningDocumentActions && state.joiningDocumentActions || [],
}

const actions = {
    fetchCustomerDocumentTypes: ({ getters, commit, dispatch }, payload) => {
        const { getCustomerId: customer_id, getOrgIDFromAccessToken: organisation_id } = getters
        let queryPayload = {};
        if (organisation_id)
            queryPayload = { ...queryPayload, organisation_id }

        queryPayload = {
            ...queryPayload,
            ...payload
        }
        // if (payload?.all_rows)
        //     queryPayload = { ...queryPayload, all_rows: payload?.all_rows }
        // if (payload?.fetch_row_count)
        //     queryPayload = { ...queryPayload, fetch_row_count: payload?.fetch_row_count }
        // if (payload?.type_name)
        //     queryPayload = { ...queryPayload, type_name: payload?.type_name }
        const queryString = getFilterQueryStringWithoutArray(queryPayload)
        return customerDocumentLib
            .getAllCustomerDocumentTypes({ customer_id, queryString })
            .then(res => {
                const { data } = res;
                commit("SET_CUSTOMER_DOCUMENT_TYPES", data);
                return res;
            })
            .catch(err => {
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: "Error While Fetching Customer Document Types",
                });
                console.log("Error While Fetching Customer Document Types", err);
                return err;
            })
    },
    fetchCustomerDocTypeCategory: ({ getters, commit, dispatch }) => {
        return customerDocumentLib
            .getDocumentCategory()
            .then(res => {
                const { data } = res;
                commit("SET_CUSTOMER_DOC_TYPE_CATEGORY", data);
                return res;
            })
            .catch(err => {
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: "Error While Fetching Customer Document Type Category",
                });
                console.log("Error While Fetching Customer Document Type Category", err);
                return err;
            })
    },
    fetchDocumentExtension: ({ getters, commit, dispatch }) => {
        return customerDocumentLib
            .getDocumentExtensions()
            .then(res => {
                const { data } = res;
                commit("SET_DOCUMENT_EXTENSION", data);
                return res;
            })
            .catch(err => {
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: "Error While Fetching Document Extension",
                });
                console.log("Error While Fetching Customer Document Extension", err);
                return err;
            })
    },
    createCustomerDocType: ({ getters, commit, dispatch }, payload) => {
        const { getCustomerId: customer_id } = getters;
        return customerDocumentLib
            .createCustomerDocType({ customer_id, payload })
            .then(res => {
                dispatch("showToast", {
                    class: "bg-success text-white",
                    message: "Document Type Added Successfully",
                });
                dispatch("fetchCustomerDocumentTypes");
                return res;
            })
            .catch(err => {
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: "Error While Create Document Type",
                });
                console.log("Error While Create Document Type", err);
                return err;
            })
    },
    updateCustomerDocType: ({ getters, commit, dispatch }, payload) => {
        const { getCustomerId: customer_id } = getters;
        return customerDocumentLib
            .updateCustomerDocType({ customer_id, payload })
            .then(res => {
                dispatch("showToast", {
                    class: "bg-success text-white",
                    message: "Document Type Updated Successfully",
                });
                dispatch("fetchCustomerDocumentTypes");
                return res;
            })
            .catch(err => {
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: "Error While Update Document Type",
                });
                console.log("Error While Update Document Type", err);
                return err;
            })
    },
    deleteCustomerDocType: ({ getters, dispatch }, customer_document_type_id) => {
        const { getCustomerId: customer_id } = getters;
        return customerDocumentLib
            .deleteCustomerDocType({ customer_id, customer_document_type_id })
            .then(res => {
                dispatch("showToast", {
                    class: "bg-success text-white",
                    message: "Document Type Deleted Successfully",
                });
                dispatch("fetchCustomerDocumentTypes");
                return res;
            })
            .catch(err => {
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: "Error While Delete Document Type",
                });
                console.log("Error While Delete Document Type", err);
                return err;
            })
    },
    fetchCustomerDocumentLib: ({ getters, commit, dispatch }, payload) => {
        const { getCustomerId: customer_uid, getOrgIDFromAccessToken: organisation_id } = getters;
        let queryPayload = {};
        if (payload)
            queryPayload = { ...queryPayload, ...payload }
        queryPayload = { ...queryPayload, all_rows: true, customer_uid, organisation_id }
        const queryString = getFilterQueryStringWithoutArray(queryPayload);
        return customerDocumentLib
            .getCustomerDocumentLib({ queryString })
            .then(res => {
                const { data } = res;
                commit("SET_CUSTOMER_DOCUMENT_LIBRARY", data);
                return res;
            })
            .catch(err => {
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: "Error While Fetching Document Lib",
                });
                console.log("Error While Fetching Document Lib", err);
                return err;
            })
    },
    createCustomerDocumentLibrary: ({ getters, commit, dispatch }, payload) => {
        return customerDocumentLib
            .createCustomerDocumentLibrary(payload)
            .then(res => {
                dispatch("showToast", {
                    class: "bg-success text-white",
                    message: "Document Library Created Successfully",
                });
                dispatch("fetchCustomerDocumentLib");
                return res;
            })
            .catch(err => {
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: "Error While Create Document Lib",
                });
                console.log("Error While Create Document Lib", err);
                return err;
            })
    },
    uploadFileToOrganisationDoc: ({ getters, dispatch, commit }, payload) => {
        dispatch("showFixedToast", { message: 'Uploading...' })
        const { getOrgIDFromAccessToken: organisation_id } = getters
        const formData = new FormData();
        if (payload.file && payload.fileName) formData.append("file", payload.file, payload.fileName);
        if (payload?.customer_document_type_id) formData.append("customer_document_type_id", payload?.customer_document_type_id);
        if (payload?.comments) formData.append("comments", payload.comments)
        return organisationDocuments
            .createDocument(organisation_id, formData)
            .then(res => {
                dispatch("hideFixedToast");
                const { data } = res
                dispatch("showToast", {
                    class: "bg-success text-white",
                    message: "File Uploaded Successfully",
                })
                return data;
            }).catch(err => {
                dispatch("hideFixedToast");
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: "Error While Uploading",
                });
                console.log("Error while creating document", err);
                return err;
            })
    },
    deleteOrganisationDocument: ({ getters, dispatch, commit }, organisation_document_id) => {
        const { getOrgIDFromAccessToken: organisation_id } = getters;
        return customerDocumentLib
            .deleteOrganisationDocument({ organisation_id, organisation_document_id })
            .then(res => {
                const { data } = res
                return data;
            })
            .catch(err => {
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: "Error While Deleting Document",
                });
                console.log("Error while Deleting Document", err);
                return err;
            })
    },
    updateCustomerDocumentLibrary: ({ getters, dispatch, commit }, payload) => {
        return customerDocumentLib
            .updateCustomerDocumentLibrary(payload)
            .then(res => {
                const { data } = res;
                dispatch("showToast", {
                    class: "bg-success text-white",
                    message: "Document Library Updated Successfully",
                })
                dispatch("fetchCustomerDocumentLib");
                return data;
            })
            .catch(err => {
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: "Error While Update Document Library",
                });
                console.log("Error while Update Document Library", err);
                return err;
            })
    },
    deleteCustomerDocumentLib: ({ getters, dispatch, commit }, document_library_id) => {
        return customerDocumentLib
            .deleteCustomerDocumentLib(document_library_id)
            .then(res => {
                const { data } = res;
                dispatch("showToast", {
                    class: "bg-success text-white",
                    message: "Document Library Deleted Successfully",
                })
                // dispatch("fetchCustomerDocumentLib");
                return data;
            })
            .catch(err => {
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: "Error While Delete Document Library",
                });
                console.log("Error while Delete Document Library", err);
                return err;
            });
    },
    createDocumentAction: ({ getters, commit, dispatch }, data) => {
        const { getCustomerId: customer_id, getOrgIDFromAccessToken: org_id } = getters;
        const formData = new FormData();
        for (const prop in data) {
            if (!["file", "fileName"].includes(prop))
                formData.append(`${prop}`, data[prop])
        }
        if (data?.file)
            formData.append("file", data?.file, data?.fileName);
        const organisation_id = data?.organisation_id || org_id;
        return customerDocumentLib
            .createDocumentAction({ customer_id, organisation_id, payload: formData })
            .then(res => {
                const { data } = res;
                dispatch("showToast", {
                    class: "bg-success text-white",
                    message: "Document Added Successfully",
                });
                return data;
            })
            .catch(err => {
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: "Error While Add Document Action",
                });
                console.log("Error While Add Document Action", err);
                return err;
            })
    },
    updateDocumentAction: ({ getters, commit, dispatch }, payload) => {
        const { getCustomerId: customer_id, getOrgIDFromAccessToken: organisation_id } = getters;
        let { document_action_id } = payload;
        return customerDocumentLib
            .updateDocumentAction({ customer_id, organisation_id, document_action_id, payload })
            .then(res => {
                const { data } = res;
                dispatch("showToast", {
                    class: "bg-success text-white",
                    message: "Document Updated Successfully",
                });
                return data;
            })
            .catch(err => {
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: "Error While Updating Document",
                });
                console.log("Error While Updating Document", err);
                return err;
            })
    },
    getJobDocumentAction: ({ getters, commit, dispatch }, payload = {}) => {
        const { getCustomerId: customer_id, getOrgIDFromAccessToken } = getters;
        const queryString = getFilterQueryStringWithoutArray({...payload,all_rows:true});
        return customerDocumentLib
            .getDocumentAction({ customer_id, queryString })
            .then(res => {
                const { data } = res;
                commit("SET_JOB_DOCUMENT_ACTION", data);
                return data;
            })
            .catch(err => {
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: "Error While Get Document Action",
                });
                console.log("Error While Get Document Action", err);
                return err;
            })
    },
    getPreOfferDocumentAction: ({ getters, commit, dispatch }, payload = {}) => {
        const { getCustomerId: customer_id, getOrgIDFromAccessToken } = getters;
        const queryString = getFilterQueryStringWithoutArray({...payload,all_rows:true});
        return customerDocumentLib
            .getDocumentAction({ customer_id, organisation_id, queryString })
            .then(res => {
                const { data } = res;
                commit("SET_PRE_OFFER_DOCUMENT_ACTION", data);
                return data;
            })
            .catch(err => {
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: "Error While Get Document Action",
                });
                console.log("Error While Get Document Action", err);
                return err;
            })
    },
    deleteDocumentAction: ({ getters, commit, dispatch }, payload) => {
        const { document_action_id } = payload;
        const { getCustomerId: customer_id } = getters;
        return customerDocumentLib
            .deleteDocumentAction({ customer_id, document_action_id })
            .then(res => {
                const { data } = res;
                dispatch("showToast", {
                    class: "bg-success text-white",
                    message: "Document Action Deleted Successfully",
                });
                return data;
            })
            .catch(err => {
                return err;
            })
    },
    getOfferDocumentAction: ({ getters, commit, dispatch }, payload) => {
        const { getCustomerId, getOrgIDFromAccessToken, isSystemAdminFromAccessToken } = getters;
        let customer_id = getCustomerId;
        if (!customer_id && isSystemAdminFromAccessToken) {
            customer_id = 1;
        }
        const queryString = getFilterQueryStringWithoutArray({...payload, all_rows:true});

        return customerDocumentLib
            .getDocumentAction({ customer_id, queryString })
            .then(res => {
                const { data } = res;
                commit("SET_OFFER_DOCUMENT_ACTION", data);
                return data;
            })
            .catch(err => {
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: "Error While Get Document Action",
                });
                console.log("Error While Get Document Action", err);
                return err;
            })
    },
    getOnBoardDocumentAction: ({ getters, commit, dispatch }, payload) => {
        const { getCustomerId, getOrgIDFromAccessToken, isSystemAdminFromAccessToken } = getters;
        let customer_id = getCustomerId;
        if (!customer_id && isSystemAdminFromAccessToken) {
            customer_id = 1;
        }
        const organisation_id = payload?.organisation_id || getOrgIDFromAccessToken;
        const queryString = getFilterQueryStringWithoutArray({...payload,all_rows:true});
        return customerDocumentLib
            .getDocumentAction({ customer_id, organisation_id, queryString })
            .then(res => {
                const { data } = res;
                commit("SET_ONBOARD_DOCUMENT_ACTION", data);
                return data;
            })
            .catch(err => {
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: "Error While Get Document Action",
                });
                console.log("Error While Get Document Action", err);
                return err;
            })
    },
    getLicensingDocumentAction: ({ getters, commit, dispatch }, payload) => {
        const { getCustomerId, getOrgIDFromAccessToken, isSystemAdminFromAccessToken } = getters;
        let customer_id = getCustomerId;
        if (!customer_id && isSystemAdminFromAccessToken) {
            customer_id = 1;
        }
        const queryString = getFilterQueryStringWithoutArray({...payload,all_rows:true});
        return customerDocumentLib
            .getDocumentAction({ customer_id, queryString })
            .then(res => {
                const { data } = res;
                commit("SET_LICENSING_DOCUMENT_ACTION", data);
                return data;
            })
            .catch(err => {
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: "Error While Get Document Action",
                });
                console.log("Error While Get Document Action", err);
                return err;
            })
    },
    getDocumentAction({ commit, getters, dispatch }, payload = {}) {
        const { getCustomerId, getOrgIDFromAccessToken, isSystemAdminFromAccessToken } = getters;
        let customer_id = getCustomerId;
        if (!customer_id && isSystemAdminFromAccessToken) {
            customer_id = 1;
        }
        const organisation_id = payload?.organisation_id || getOrgIDFromAccessToken;
        const queryString = getFilterQueryStringWithoutArray({...payload,all_rows:true});
        return customerDocumentLib
            .getDocumentAction({ customer_id, organisation_id, queryString })
            .then(res => {
                const { data } = res;
                const preOfferDocs = data.filter(({ action_type }) => action_type === 'pre_offer') || []
                const offerDocs = data.filter(({ action_type }) => action_type === 'offer') || []
                const licensingDocs = data.filter(({ action_type }) => action_type === 'licensing') || []
                const visaDocs = data.filter(({ action_type }) => action_type === 'visa') || []
                const joiningDocs = data.filter(({ action_type }) => action_type === 'joining') || [];
                commit("SET_PRE_OFFER_DOCUMENT_ACTION", preOfferDocs);
                commit("SET_OFFER_DOCUMENT_ACTION", offerDocs);
                commit("SET_LICENSING_DOCUMENT_ACTION", licensingDocs);
                commit("SET_ONBOARD_DOCUMENT_ACTION", licensingDocs);
                commit("SET_VISA_DOCUMENT_ACTION", visaDocs);
                commit("SET_JOINING_DOCUMENT_ACTION", joiningDocs);
                return data;
            })
            .catch(err => {
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: "Error While Get Document Action",
                });
                console.log("Error While Get Document Action", err);
                return err;
            })
    }
}

const mutations = {
    ["SET_CUSTOMER_DOCUMENT_TYPES"](state, payload) {
        state.customerDocumentTypes = payload;
    },
    ["SET_CUSTOMER_DOC_TYPE_CATEGORY"](state, payload) {
        state.customerDocTypeCategory = payload;
    },
    ["SET_DOCUMENT_EXTENSION"](state, payload) {
        state.documentExtensions = payload;
    },
    ["SET_CUSTOMER_DOCUMENT_LIBRARY"](state, payload) {
        state.customerDocumentLibrary = payload;
    },
    ["SET_JOB_DOCUMENT_ACTION"](state, payload) {
        state.jobDocumentActions = payload;
    },
    ["SET_OFFER_DOCUMENT_ACTION"](state, payload) {
        state.offerDocumentActions = payload;
    },
    ["SET_ONBOARD_DOCUMENT_ACTION"](state, payload) {
        state.onboardDocumentActions = payload;
    },
    ["SET_PRE_OFFER_DOCUMENT_ACTION"](state, payload) {
        state.preOfferDocumentActions = payload;
    },
    ["SET_LICENSING_DOCUMENT_ACTION"](state, payload) {
        state.licensingDocumentActions = payload;
    },
    ["SET_VISA_DOCUMENT_ACTION"](state, payload) {
        state.visaDocumentActions = payload;
    },
    ["SET_JOINING_DOCUMENT_ACTION"](state, payload) {
        state.joiningDocumentActions = payload;
    },
}
export default { state, getters, mutations, actions };